.news{
    padding-top: 150px;
    padding-bottom: 80px;
    background-color: #FDFDFD;
}

.news .title{
    text-align: center;
    font-size: 50px;
    font-weight: 700;
    color: #1A1A1A;
    position: relative;
}

.news .title::before,
.news .title::after{
    content: '';
    width: 80px;
    position: absolute;
    top: 50%;
    height: 4px;
    border-radius: 2px;
    background-color: var(--primary);
}
.news .title::before{
    right: 27%
}
.news .title::after{
    left: 27%
}
.p-carousel-indicators{
    display: none;
}
.news .p-carousel-next,
.news .p-carousel-prev{
    box-shadow: 0px 0px 16px 0px #0000000F;
    border-radius: 50%;
}
.news .new-card{
    font-family: "Tajawal", sans-serif !important;
    text-align: right;
    direction: rtl;
    border-radius: 16px;
    box-shadow: 0px 0px 16px 0px #0000000F;
}

.news .new-card .card-description{
    color: #ADADAD;
    margin-bottom: 0;
}
.news .new-card img{
    border-radius: 3px;
}

.news .new-card .card-title{
    font-size: 16px;
    font-weight: 700;
    margin-top: 22px;
    margin-bottom: 22px;
    color: #1A1A1A;
}
.news .more a{
    background-color: #289566;
    padding: 15px 48px 15px 48px;
    border: none;
    color: #fff;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
}
.news .more{
    margin-top: 30px;
}
@media screen and (min-width: 500px) and (max-width: 800px){
    .news{
        padding-top: 60px
    }
    .news .title{
        font-size: 40px;
    }
    .news .title::before{
        right: 10%
    }
    .news .title::after{
        left: 10%
    }
    .news .p-carousel .p-carousel-item .m-4{
        margin: 8px!important;
    }
    .market-card .hours {
        font-size: 16px;
    }
}
@media screen and (max-width: 500px){
    .news{
        padding-top: 70px;
    }
    .news .title{
        font-size: 16px;
    }
    .news .title::before,
    .news .title::after{
        width: 70px;
    }
    .news .title::before{
        right: 17%
    }
    .news .title::after{
        left: 17%
    }
    .p-carousel{
        margin-top: 10px!important;
    }
    .news .more a{
        margin-top: 28px;
    }
}