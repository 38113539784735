.gallery{
    padding-top: 150px;
    padding-bottom: 80px;
    background-color: #FDFDFD;
}
.gallery .tabs-div{
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
    font-size: 1.3rem;
    font-weight: 600;
    gap: 30px;
}
.gallery .tabs-div p{
    padding: 8px 16px;
    background: #dedede;
    border-radius: 7px;
    cursor: pointer;
}
.gallery .tabs-div p.active{
    background-color: var(--primary);
    color: #fff;
}
.gallery .title{
    text-align: center;
    font-size: 50px;
    font-weight: 700;
    color: #1A1A1A;
    position: relative;
}

.gallery .title::before,
.gallery .title::after{
    content: '';
    width: 80px;
    position: absolute;
    top: 50%;
    height: 4px;
    border-radius: 2px;
    background-color: var(--primary);
}
.gallery .title::before{
    right: 27%
}
.gallery .title::after{
    left: 27%
}
.p-carousel-indicators{
    display: none;
}
.gallery .p-carousel-next,
.gallery .p-carousel-prev{
    box-shadow: 0px 0px 16px 0px #0000000F;
    border-radius: 50%;
}
.gallery .new-card{
    text-align: right;
    direction: rtl;
    border-radius: 16px;
    box-shadow: 0px 0px 16px 0px #0000000F;
}

.gallery .new-card .card-description{
    color: #000;
    font-size: 19px;
    margin-bottom: 0;
}
.gallery .new-card img{
    border-radius: 3px;
}

.gallery .new-card .card-title{
    font-size: 16px;
    font-weight: 700;
    margin-top: 22px;
    margin-bottom: 22px;
    color: #1A1A1A;
}
.gallery .more a{
    background-color: #289566;
    padding: 15px 48px 15px 48px;
    border: none;
    color: #fff;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
}
.gallery .more{
    margin-top: 30px;
}
@media screen and (min-width: 500px) and (max-width: 800px){
    .gallery{
        padding-top: 110px
    }
    .gallery .title{
        font-size: 40px;
    }
    .gallery .title::before{
        right: 10%
    }
    .gallery .title::after{
        left: 10%
    }
    .gallery .p-carousel .p-carousel-item .m-4{
        margin: 8px!important;
    }
    .market-card .hours {
        font-size: 16px;
    }
}
@media screen and (max-width: 500px){
    .gallery{
        padding-top: 100px;
    }
    .gallery .title{
        font-size: 18px;
    }
    .gallery .title::before,
    .gallery .title::after{
        width: 70px;
    }
    .gallery .title::before{
        right: 17%
    }
    .gallery .title::after{
        left: 17%
    }
    .p-carousel{
        margin-top: 10px!important;
    }
    .gallery .more a{
        margin-top: 28px;
    }
}