.teams{
    padding-top: 150px;
    padding-bottom: 80px;
    background-color: #FDFDFD;
}
.teams .tabs-div{
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 3rem;
    font-size: 1.3rem;
    font-weight: 600;
    gap: 16px;
}
.teams .tabs-div p{
    padding: 16px;
    background: #dedede;
    border-radius: 7px;
    cursor: pointer;
}
.teams .tabs-div p.active{
    background-color: var(--primary);
    color: #fff;
}
.teams .title{
    text-align: center;
    font-size: 50px;
    font-weight: 700;
    color: #1A1A1A;
    position: relative;
}
.teams .title::before,
.teams .title::after{
    content: '';
    width: 80px;
    position: absolute;
    top: 50%;
    height: 4px;
    border-radius: 2px;
    background-color: var(--primary);
}
.teams .title::before{
    right: 27%
}
.teams .title::after{
    left: 27%
}
.teams .new-card{
    text-align: right;
    direction: rtl;
    border-radius: 16px;
    box-shadow: 0px 0px 16px 0px #0000000F;
}
.teams .new-card .card-description{
    color: #000;
    font-size: 19px;
    margin-bottom: 0;
}
.teams .new-card img{
    border-radius: 3px;
}
.teams .new-card .card-title{
    font-size: 19px;
    font-weight: 600;
    margin-top: 20px;
    color: #1a1a1ad4;
}
.teams .contact button{
    background-color: #289566;
    padding: 15px 48px 15px 48px;
    border: none;
    color: #fff;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 500;
}
.teams .contact{
    border: 1px solid #dedede;
    border-radius: 8px;
    padding: 4rem 8px;
    min-height: auto;
}
.teams .contact label{
    display: block;
}
.teams .contact textarea{
    min-height: 100px;
    max-height: 150px;
}
.teams .contact textarea:active,
.teams .contact input:active{
    border: none;
}
.teams .contact textarea,
.teams .contact input{
    padding: 12px 8px;
    width: 100%;
    border: none;
    border-bottom: 1px solid #ADADAD
}
@media screen and (min-width: 500px) and (max-width: 800px){
    .teams{
        padding-top: 100px
    }
    .teams .tabs-div {
        font-size: 1rem;
        font-weight: 500;
        gap: 8px;
    }
    .teams .tabs-div p{
        padding: 8px 10px;
    }
    .teams .title{
        font-size: 40px;
    }
    .teams .title::before{
        right: 10%
    }
    .teams .title::after{
        left: 10%
    }
}
@media screen and (max-width: 500px){
    .teams{
        padding-top: 70px;
    }
    .teams .title{
        font-size: 16px;
    }
    .teams .title::before,
    .teams .title::after{
        width: 70px;
    }
    .teams .title::before{
        right: 17%
    }
    .teams .title::after{
        left: 17%
    }
}