.activities{
    padding: 100px 0 60px;
    text-align: center;
    background-color: #FDFDFD;
    overflow: hidden;
}
.activities .title{
    font-size: 50px;
    font-weight: 700;
    color: #1A1A1A;
    position: relative;
}

.activities .title::before,
.activities .title::after{
    content: '';
    width: 80px;
    position: absolute;
    top: 50%;
    height: 4px;
    border-radius: 2px;
    background-color: var(--primary);
}
.activities .title::before{
    right: 32%
}
.activities .title::after{
    left: 32%
}
.activities-card{
    margin-top: 80px;
}
.activities-card .activity-card{
    text-align: right;
    padding: 24px;
    height: 100%;
    border-radius: 16px;
    margin: 0 2rem;
    box-shadow: 0.8433734774589539px 12.650602340698242px 19.39759063720703px 0px #E5E5E540;
}
.activities-card .activity-card img{
    margin-bottom: 42px;
}
.activities-card .activity-card p{
    color: #767676;
    font-size: 20px;
    font-weight: 500;
}
.more a{
    background-color: #289566;
    padding: 15px 48px 15px 48px;
    border: none;
    color: #fff;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
}
@media screen and (min-width: 500px) and (max-width: 800px){
    .activities{
        padding: 90px 0 60px;
    }
    .activities .title{
        font-size: 40px;
    }
    .activities .title::before{
        right: 23%
    }
    .activities .title::after{
        left: 23%
    }
    .activities-card .activity-card,
    .activities-card .activity-card p{
        margin: 0;
    }
}
@media screen and (max-width: 500px){
    .activities{
        padding:60px 0 10px;
    }
    .activities .title{
        font-size: 16px;
    }
    .activities .title::before,
    .activities .title::after{
        width: 65px;
    }
    .activities .title::before{
        right: 21%
    }
    .activities .title::after{
        left: 21%
    }
    .activities-card{
        margin-top: 12px;
    }
}