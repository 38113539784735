.filter-pagination{
    background: #f9f9f9;
    border-radius: 10px;
    display: inline-block;
    padding: 5px;
}
.previous-button,
.next-button{
        background-color: #fff;
        border: 0!important;
        border-radius: 10px;
        color: #7e7e7e;
        display: inline-block;
        font-size: 16px;
        margin: 0 5px;
        padding: 14px 20px;
        width: auto;
}

button[disabled] {
        cursor: default;
        opacity: .5;
}