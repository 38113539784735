.navbar-nav .nav-link.active{
    color: var(--primary) !important;
    font-weight: 700 !important;
}
.navbar{
    background-color: #fff;
    box-shadow: 0.8433734774589539px 12.650602340698242px 19.39759063720703px 0px #E5E5E540;
}
.navbar-expand-lg .navbar-nav .dropdown-menu{
    text-align: right;
    border-color: #dedede63;
}
.dropdown-toggle::after{
    display: none;
}
@media screen and (max-width: 500px){
    .dropdown-menu.show{
        border: none;
    }
}