.footer{
    background: radial-gradient(50% 50% at 50% 50%, #2dc081 0%, #289566 100%);
    padding: 16px;
    margin-top: 116px;
}
.apps,
.social {
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
}
.social p,
.apps p{
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    margin: 0;
}
.apps .icons{
    margin-bottom: 16px;
    margin-top: 8px;
}
.apps a{
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    text-decoration: none;
    margin-right: 12px;
}
.social .icons{
    margin-top: 24px;
}
.social .icons a{ 
    margin-left: 16px;
}
.footer hr{
    color: #fff;
    margin: 8px;
}
.footer .power{
    font-size: 16px;
    color: #fff;
    font-weight: 700;
    line-height: 19.2px;
    margin-top: 12px;
}
@media screen and (min-width: 500px) and (max-width: 800px){
    .footer {
        margin-top: 60px;
    }
}
@media screen and (max-width: 500px) {
    .footer .logo{
        text-align: center;
        margin-bottom: 16px;
    }
    .footer .social{
        margin-bottom: 16px;
    }
    hr{
        display: none;
    }
}