.talabat .talabat-img{
    width: 100%;
    height: 90%;
}
.talabat .title{
    text-align: center;
    font-size: 50px;
    margin-bottom: 32px;
    font-weight: 700;
    color: #1A1A1A;
    position: relative;
}
.talabat .title::before,
.talabat .title::after{
    content: '';
    width: 80px;
    position: absolute;
    top: 50%;
    height: 4px;
    border-radius: 2px;
    background-color: var(--primary);
}
.talabat .title::before{
    right: 16%
}
.talabat .title::after{
    left: 16%
}
.talabat .order-now{
    padding: 0 80px;
    text-align: end;
}
.talabat .order-now a{
    background-color: #FF6F00;
    text-decoration: none;
    padding: 18px 48px;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-weight: 700;
}
@media screen and (min-width: 500px) and (max-width: 800px){
    .talabat img{
        width: 100%!important;
        height: 100%!important;
    }
    .talabat .title {
        font-size: 40px;
        line-height: 50px;
    }
    .talabat .title::before{
        right: 7%
    }
    .talabat .title::after{
        left: 7%
    }
}
@media screen and (max-width: 500px){
    .talabat img{
        width: 100%!important;
    }
    .talabat .title{
        font-size: 18px;
        line-height: 38.6px;
        margin-top: 37px;
        text-align: center;
    }
    .talabat .order-now button{
        background: none;
        width: 100%;
        border: 1px solid #fff;
        margin-top: 37px;
    }
    .talabat .title::after,
    .talabat .title::before{
        width: 50px;
    }
    .talabat .title::before{
        right: 9%
    }
    .talabat .title::after{
        left: 9%
    }
}